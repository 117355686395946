
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data() {
    return;
    {
      data: null;
    }
  },

  on: {
    pageBeforeIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
    },
    pageAfterIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;

      let _user_logged = appUtilities.user_is_logged();

      if (_user_logged == false) {
        return;
      } // Carica il profilo dell'utente


      var _request_params = {
        'session_token': appCore.session_token
      };

      _app.request({
        url: api_url + 'driver_profile.php',
        dataType: 'json',
        data: _request_params,
        method: 'POST',
        cache: false,
        crossDomain: true,
        success: function (profileData) {
          console.log("profile data", profileData);
          let _page = $$('.page[data-name="profile"]')[0].f7Page;
          let _self = _page.$pageEl[0].f7Component;
          let _periodOrdersData = {
            orders_count: 0,
            cash_totals: 0,
            cards_totals: 0,
            orders_totals: 0
          }; // NB: "The date should take the format YYYY-MM-DD"
          // https://stackoverflow.com/questions/14212527/how-to-set-default-value-to-the-inputtype-date

          let _default_period_from = moment().clone().startOf('month').format("YYYY-MM-DD");

          let _default_period_to = moment().clone().endOf('month').format("YYYY-MM-DD");

          _self.$setState({
            profileData: profileData.data,
            periodOrdersData: _periodOrdersData,
            period_from: _default_period_from,
            period_to: _default_period_to
          }); //load_period_totals(_default_period_from, _default_period_to);

        },
        error: function () {
          _app.dialog.alert(general_error_message, 'Attenzione', function () {}, "Errore");
        }
      });
    },
    pageInit: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
    }
  },
  id: '9cb9b41395',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=profile style="background-color: #fff;"><!-- Fixed/Dynamic Navbar --><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=title>Profilo</div><div class=right><a id=logout href=# class=link>Esci</a></div></div></div><!-- Scrollable page content--><div class=page-content>';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.profileData === 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<!-- Pagina caricamento --><div class="list media-list"><ul><li class="item-content skeleton-text skeleton-effect-wave"><div class=item-media><div class=skeleton-block style="width: 40px; height: 40px; border-radius: 50%"></div></div><div class=item-inner><div class=item-title-row><div class=item-title>Title</div></div><div class=item-subtitle>Subtitle</div><div class=item-text>Placeholder text line 1<br>Text line 2</div></div></li><li class="item-content skeleton-text skeleton-effect-wave"><div class=item-media><div class=skeleton-block style="width: 40px; height: 40px; border-radius: 50%"></div></div><div class=item-inner><div class=item-title-row><div class=item-title>Title</div></div><div class=item-subtitle>Subtitle</div><div class=item-text>Placeholder text line 1<br>Text line 2</div></div></li></ul></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.profileData !== 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=card><!-- Card body --><div class="card-content card-content-padding"><div class="list no-hairlines-md"><ul><li class="item-content item-input"><div class=item-inner><div class="item-title item-label"><span style="font-weight: bold;">Nome</span></div><div class=item-input-wrap><input id=profile-name type=text placeholder=Nome value="';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.profileData.first_name, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"> <span class=input-clear-button></span></div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label"><span style="font-weight: bold;">Cognome</span></div><div class=item-input-wrap><input id=profile-surname type=text placeholder=Cognome value="';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.profileData.last_name, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"> <span class=input-clear-button></span></div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label"><span style="font-weight: bold;">Telefono</span></div><div class=item-input-wrap><input id=profile-phone-number type=text placeholder=Telefono value="';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.profileData.phone, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" readonly=readonly></div></div></li><!--\n              <li class="item-content item-input">\n                <div class="item-inner">\n                  <div class="item-title item-label">E-mail</div>\n                  <div class="item-input-wrap">\n                    <input type="email" placeholder="Email" value="';
          r += c(ctx_2.profileData.email, ctx_2);
          r += '">\n                    <span class="input-clear-button"></span>\n                  </div>\n                </div>\n              </li>\n              --></ul></div><!--\n          <button id="update-profile" class="col button button-large button-fill" style="min-height: 50px; margin-top: 20px;">Salva modifiche</button>\n          --></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class=block-title style="font-size: larger;"><b>Totale consegne nel periodo</b></div>';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.periodOrdersData !== 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=card><!-- Card body --><div class="card-content card-content-padding"><div class="list no-hairlines-md"><ul><!-- FILTRO DATA --><li><div class=item-content><span>Dal </span><span><input class=period-filter id=from-period type=date value=';
          r += c(ctx_2.period_from, ctx_2);
          r += ' style="border: 1px solid #b11733;"></span></div><div class=item-content><span>Al</span> <span><input class=period-filter id=to-period type=date value=';
          r += c(ctx_2.period_to, ctx_2);
          r += ' style="border: 1px solid #b11733;"></span></div></li><li><div class=item-content><div class=item-inner><b>Ordini: ';
          r += c(ctx_2.periodOrdersData.orders_count, ctx_2);
          r += '</b></div></div></li><li><div class=item-content><div class=item-inner><b>Contanti: ';
          r += c(ctx_2.periodOrdersData.cash_totals, ctx_2);
          r += '&euro;</b></div></div></li><li><div class=item-content><div class=item-inner><b>Carte di credito: ';
          r += c(ctx_2.periodOrdersData.cards_totals, ctx_2);
          r += '&euro;</b></div></div></li><li><div class=item-content><div class=item-inner><b>Totale: ';
          r += c(ctx_2.periodOrdersData.orders_totals, ctx_2);
          r += '&euro;</b></div></div></li></ul></div></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class=card><!-- Card body --><div class="card-content card-content-padding"><a href=/app-info/ >Info applicazione</a></div></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    