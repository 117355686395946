export default {
  id: '44fc59c14e',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div id=app><!-- Views/Tabs container --><div class="views tabs safe-areas"><!-- Tabbar for switching views-tabs --><div style="display: none;" class="toolbar toolbar-bottom tabbar-labels"><div id=main-toolbar class=toolbar-inner><a id=tab-available-orders href=#view-available-orders class=tab-link><img height=25px src=./static/images/cart.png> <span class=tabbar-label style="font-weight: bold; font-size: 1.2em;">Disponibili</span> </a><a id=tab-accepted-orders href=#view-accepted-orders class=tab-link><img height=25px src=./static/images/cart.png> <span class=tabbar-label style="font-weight: bold; font-size: 1.2em;">Accettati</span> </a><a id=tab-profile href=#view-profile class=tab-link><img height=25px src=./static/images/profile.png> <span class=tabbar-label style="font-weight: bold; font-size: 1.2em;">Profilo</span></a></div></div><!-- Login view --><div id=view-login class="view view-main tab tab-active" data-name=login data-url=/login/ ></div><!-- Available orders view --><div id=view-available-orders class="view view-init tab" data-name=available-orders data-url=/available-orders/ ></div><!-- Accepted orders view --><div id=view-accepted-orders class="view view-init tab" data-name=accepted-orders data-url=/accepted-orders/ ></div><!-- Profile view --><div id=view-profile class="view view-init tab" data-name=profile data-url=/profile/ ></div></div></div>';
      return r;
    }(this);
  },

  style: `
/* Fix "elastic scrolling" su iOS */

html,
body 
{
  height: 100% !important;
  width: 100% !important;
  overflow: auto !important;
}
`,
  styleScoped: false
};