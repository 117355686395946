export default {
  id: '6a5af49acc',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page style="background-color: #fff;"><div class="page-content login-screen-content"><div class=login-screen-title><img src=./static/images/jacknb_logo.png style="max-width: 40%;"></div><form><div class=list><ul><li class="item-content item-input"><div class=item-inner><div class="item-title item-label"><b>Email</b></div><div class=item-input-wrap><input id=signup-email type=text name=username placeholder="Email di registrazione" required validate></div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label"><b>Password</b></div><div class=item-input-wrap><input id=signup-password type=password name=password placeholder=Password required validate></div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label"><b>Nome</b></div><div class=item-input-wrap><input id=signup-name type=text name=name placeholder=Nome required validate></div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label"><b>Cognome</b></div><div class=item-input-wrap><input id=signup-surname type=text name=surname placeholder=Cognome required validate></div></div></li><li class="item-content item-input"><div class=item-inner><div class="item-title item-label"><b>Telefono</b> [es. 333445566777 senza +39]</div><div class=item-input-wrap><input id=signup-phone type=text name=phone placeholder=Telefono required validate></div></div></li><li><label class="item-checkbox item-content"><input id=accept-terms type=checkbox name=terms-checkbox value=Books> <i class="icon icon-checkbox"></i><div class=item-inner><div class=item-title><a href=/terms/ >Accetto i termini e le condizioni</a></div></div></label></li></ul></div><div class=block><p class=row><button id=signup-save class="col button button-outline button-raised">Salva</button></p></div></form><div class=block><p class=row><a href=# class="link back">Indietro</a></p></div></div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};