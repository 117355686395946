
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data() {
    return;
    {
      data: null;
    }
  },

  on: {
    pageBeforeIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
    },
    pageAfterIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
      var _request_params = {
        'session_token': appCore.session_token
      };

      _app.request({
        url: api_url + 'customer_help.php',
        dataType: 'json',
        data: _request_params,
        method: 'POST',
        cache: false,
        crossDomain: true,
        success: function (helpData) {
          console.log("customer help data", helpData);

          _self.$setState({
            data: helpData.data
          });
        },
        error: function () {
          _app.dialog.alert(general_error_message, 'Attenzione', function () {}, "Errore");
        }
      });
    },
    pageInit: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
    }
  },
  id: 'c10527f48d',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page style="background-color: #fff;"><!-- Fixed/Dynamic Navbar --><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left><a class="link back"><i class="icon icon-back"></i> <span class=if-not-md>Indietro</span></a></div><div class=right></div></div></div><!-- Scrollable page content--><div class=page-content>';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data === 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<!-- Pagina caricamento --><div class="list media-list"><ul><li class="item-content skeleton-text skeleton-effect-wave"><div class=item-media><div class=skeleton-block style="width: 40px; height: 40px; border-radius: 50%"></div></div><div class=item-inner><div class=item-title-row><div class=item-title>Title</div></div><div class=item-subtitle>Subtitle</div><div class=item-text>Placeholder text line 1<br>Text line 2</div></div></li><li class="item-content skeleton-text skeleton-effect-wave"><div class=item-media><div class=skeleton-block style="width: 40px; height: 40px; border-radius: 50%"></div></div><div class=item-inner><div class=item-title-row><div class=item-title>Title</div></div><div class=item-subtitle>Subtitle</div><div class=item-text>Placeholder text line 1<br>Text line 2</div></div></li></ul></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data !== 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=card><div class=card-header><p>Hai bisogno di assistenza? Non esitare a contattare il responsabile della tua zona! <span style="color: #B23633;">Nota bene: il responsabile è riferito al luogo dove ha sede il ristorante dal quale hai ordinato</span></p></div><div class="card-content card-content-padding"><div class=list style="min-width: 100%;"><ul>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.data, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li><a href=tel:';
              r += c(ctx_3.area_manager_phone, ctx_3);
              r += ' class="item-link item-content external"><div class=item-inner><div class=item-title-row><div class=item-title>';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.area_name, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div><div class=item-after></div></div><div class=item-subtitle>';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.area_manager_phone, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div></div></a></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    