
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data() {
    return;
    {
      data: null;
    }
  },

  on: {
    pageAfterIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
      f7app.toolbar.show('.toolbar', false);
      console.log('routes /order-details/');
      var _order_id = _route.query.order_id;
      var _request_params = {
        'order_id': _order_id,
        'session_token': appCore.session_token
      };

      _app.request({
        url: api_url + 'get_order_details.php',
        dataType: 'json',
        data: _request_params,
        method: 'POST',
        cache: false,
        crossDomain: true,
        success: function (orderDetailsData) {
          console.log('order details data', orderDetailsData);

          _self.$setState({
            data: orderDetailsData.data
          });
        },
        error: function () {
          _app.dialog.alert(general_error_message, 'Attenzione', function () {}, "Errore");
        }
      });
    }
  },
  id: 'c14fc1bc14',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=order-details><!-- Fixed/Dynamic Navbar --><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><!--BACK TO ORDERS--> ';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data === 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=left><a href=#><i class="icon icon-back"></i> <span class=if-not-md></span></a></div><div class=title>Dettagli ordine</div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data !== 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.driver_details === null", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <a href=/available-orders/ ><i class="icon icon-back"></i> <span class=if-not-md></span> </a>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.driver_details !== null", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, "this.data.driver_details.driver_status >= 1", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' <a href=/accepted-orders/ ><i class="icon icon-back"></i> <span class=if-not-md></span> </a>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '<div class=title>Dettagli ordine ';
          r += c(ctx_2.data.order_number, ctx_2);
          r += '</div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div><!-- Scrollable page content--><div class=page-content>';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data === 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<!-- Pagina caricamento --><div class="list media-list"><ul><li class="item-content skeleton-text skeleton-effect-wave"><div class=item-media><div class=skeleton-block style="width: 40px; height: 40px; border-radius: 50%"></div></div><div class=item-inner><div class=item-title-row><div class=item-title>Title</div></div><div class=item-subtitle>Subtitle</div><div class=item-text>Placeholder text line 1<br>Text line 2</div></div></li><li class="item-content skeleton-text skeleton-effect-wave"><div class=item-media><div class=skeleton-block style="width: 40px; height: 40px; border-radius: 50%"></div></div><div class=item-inner><div class=item-title-row><div class=item-title>Title</div></div><div class=item-subtitle>Subtitle</div><div class=item-text>Placeholder text line 1<br>Text line 2</div></div></li></ul></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data !== 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="card card-outline"><!-- Card body --><div class="card-content card-content-padding"><!-- Dettagli ristorante --><div class="list media-list"><ul><li><div class=item-content><div class=item-inner><div class=item-title-row><div class=item-title style="font-weight: bold;">Dettagli ristorante</div></div><div class=item-subtitle>Nome: ';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.data.restaurant_name, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div><div class=item-subtitle>Indirizzo: ';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.data.restaurant_address, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div></div></li></ul></div><!-- Dettagli cliente --><div class="list media-list"><ul><li><div class=item-content><div class=item-inner><div class=item-title-row><div class=item-title style="font-weight: bold;">Dettagli cliente</div></div><div class=item-subtitle>Nome: ';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.data.name, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div></div></li></ul></div><!-- Dettaglio ordine --><div class="list media-list" style="margin-top: 15px;"><ul><li><div class=item-content><div class=item-inner><div class=item-title-row><div class=item-title style="font-weight: bold;">Dettaglio ordine</div></div></div></div></li></ul></div><div class="list media-list"><ul>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.data.item_details, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li><div class=item-content><div class=item-inner><div class=item-title-row><div class=item-title style="height: auto; white-space: pre-wrap;">';
              r += c(ctx_3.item_qty, ctx_3);
              r += ' x ';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.name, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div><div class=item-after>';
              r += c(ctx_3.item_total, ctx_3);
              r += '&euro;</div></div><div class=item-subtitle style="margin-left: 10px; font-style: italic; height: auto; white-space: pre-wrap;">';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.ingredients_description, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div><div class=item-text></div></div></div></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div><div class="list media-list" style="margin-top: 15px;"><ul><!-- Note ordine --><li><div class=item-content><div class=item-inner><div class=item-title-row><div class=item-title style="font-weight: bold;">Note ordine</div></div><div style="height: auto; white-space: pre-wrap;">';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.data.instruction, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div></div></li><!-- Totali ordine --><li><div class=item-content><div class=item-inner><div class=item-title-row><div class=item-title style="font-weight: bold;">Totali</div></div><div class=item-row><div class=item-subtitle>Totale prodotti:</div><div class=item-after>';
          r += c(ctx_2.data.item_total, ctx_2);
          r += '&euro;</div></div><div class=item-row><div class=item-subtitle>Costo spedizione:</div><div class=item-after>';
          r += c(ctx_2.data.shipping_cost, ctx_2);
          r += '&euro;</div></div><div class=item-row><div class=item-subtitle>Totale complessivo:</div><div class=item-after>';
          r += c(ctx_2.data.grand_total, ctx_2);
          r += '&euro;</div></div></div></div></li><!-- Metodo di pagamento --><li style="background-color: ';
          r += c(ctx_2.data.bg_color, ctx_2);
          r += ';"><div class=item-content><div class=item-inner><div class=item-title-row><div class=item-title style="font-weight: bold;">Metodo di pagamento</div></div><div class=item-subtitle>';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.payment_type_code == 'credit_card'", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' Carta di credito ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' Contanti alla consegna ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div></div></li><!-- Orario di ritiro --><li><div class=item-content><div class=item-inner><div class=item-title-row><div class=item-title style="font-weight: bold;">Orario di ritiro</div></div><div class=item-subtitle>';
          r += Template7Helpers.js.call(ctx_2, "appUtilities.convertUTCToLocalDate(this.data.restaurant_time_limit).format('HH:mm')", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div></div></li><!-- Giorno di consegna --><li><div class=item-content><div class=item-inner><div class=item-title-row><div class=item-title style="font-weight: bold;">Giorno di consegna</div></div><div class=item-subtitle>';
          r += Template7Helpers.js.call(ctx_2, "appUtilities.convertUTCToLocalDate(this.data.order_date).format('DD/MM/YYYY')", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div></div></li><!-- Orario di consegna --><li><div class=item-content><div class=item-inner><div class=item-title-row><div class=item-title style="font-weight: bold;">Orario di consegna</div></div><div class=item-subtitle>';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.time_limit_status == 'pre_order'", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += c(ctx_3.data.time_limit, ctx_3);
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.time_limit_status != 'pre_order'", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' Il prima possibile ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div></div></div></li><!-- Indirizzo di consegna --><li><div class=item-content><div class=item-inner><div class=item-title-row><div class=item-title style="font-weight: bold;">Indirizzo di consegna</div></div><div class=item-subtitle>';
          r += Template7Helpers.escape.call(ctx_2, ctx_2.data.order_address, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' [';
          r += c(ctx_2.data.distance, ctx_2);
          r += ' Km]</div></div></div></li><!-- Chiama cliente --><li><div class=item-content><div class=item-inner><div class=item-title-row><a href=tel:';
          r += c(ctx_2.data.phone_number, ctx_2);
          r += ' class="external col button button-fill" style="min-height: 30px; min-width: 100%;">Chiama cliente</a></div></div></div></li>';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.driver_details !== null", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, "this.data.driver_details.driver_status == 2", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<!-- Avvio navigatore cliente --><li><div class=item-content><div class=item-inner><div class=item-title-row><a href="google.navigation:q=';
                  r += c(ctx_4.data.order_address_latitude, ctx_4);
                  r += ',';
                  r += c(ctx_4.data.order_address_longitude, ctx_4);
                  r += '" class="external col button button-fill" style="min-height: 30px; min-width: 100%;">Avvia navigatore cliente</a></div></div></div></li>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div></div></div><!--Ora ritiro e accetta/rifiuta--><div class="card card-outline"><!--ORDINE DA GESTIRE--><!-- Card header --> ';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.driver_details === null", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<!-- Azioni ordine --><div class="card-content card-content-padding"><div class=row><button id=accept data-order-id=';
              r += c(ctx_3.data.order_id, ctx_3);
              r += ' class="col button button-small button-fill" style="min-height: 50px;">ACCETTA</button></div></div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          r += Template7Helpers.js_if.call(ctx_2, "this.data.driver_details !== null", {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, "this.data.driver_details.driver_status == 1", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<!-- Accettato --><div class="card-content card-content-padding"><div class=row><button id=pickup data-order-id=';
                  r += c(ctx_4.data.order_id, ctx_4);
                  r += ' class="col button button-small button-fill" style="min-height: 50px;">PRELEVA</button></div></div><div class="card-content card-content-padding"><div class=row><button id=reject data-order-id=';
                  r += c(ctx_4.data.order_id, ctx_4);
                  r += ' class="col button">RIFIUTA</button></div></div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, "this.data.driver_details.driver_status == 2", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class="card-content card-content-padding"><div class=row><button id=delivered data-order-id=';
                  r += c(ctx_4.data.order_id, ctx_4);
                  r += ' class="col button button-small" style="min-height: 50px; background-color: #43db43; color: #000;">CONSEGNATO</button></div></div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};
    