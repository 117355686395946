
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import $$ from 'dom7';
export default {
  data() {
    return;
    {
      data: null;
    }
  },

  on: {
    pageTabShow: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
      console.log("route [tab show] /available-orders/");
      load_available_orders(_app);
    },
    pageBeforeIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;
    },
    pageAfterIn: function (e, page) {
      var _self = this;

      var _app = _self.$app;
      var _route = _self.$route;

      let _user_logged = appUtilities.user_is_logged();

      if (_user_logged == false) {
        return;
      }

      console.log("route [after in] /available-orders/");
      load_available_orders(_app);
    }
  },
  id: 'dcc1a8689a',

  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }

      function isFunction(func) {
        return typeof func === 'function';
      }

      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }

      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=available-orders style="background-color: #fff;"><!-- Fixed/Dynamic Navbar --><div class=navbar><div class=navbar-bg></div><div class="navbar-inner sliding"><div class=left></div>';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data === 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=title>Caricamento...</div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data !== 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=title>Ordini disponibili: ';
          r += Template7Helpers.js.call(ctx_2, "this.data.length", {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class=right></div></div></div><!-- Scrollable page content--><div class=page-content>';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data === 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<!-- Pagina caricamento --><div class="list media-list"><ul><li class="item-content skeleton-text skeleton-effect-wave"><div class=item-media><div class=skeleton-block style="width: 40px; height: 40px; border-radius: 50%"></div></div><div class=item-inner><div class=item-title-row><div class=item-title>Title</div></div><div class=item-subtitle>Subtitle</div><div class=item-text>Placeholder text line 1<br>Text line 2</div></div></li><li class="item-content skeleton-text skeleton-effect-wave"><div class=item-media><div class=skeleton-block style="width: 40px; height: 40px; border-radius: 50%"></div></div><div class=item-inner><div class=item-title-row><div class=item-title>Title</div></div><div class=item-subtitle>Subtitle</div><div class=item-text>Placeholder text line 1<br>Text line 2</div></div></li></ul></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.js_if.call(ctx_1, "typeof this.data !== 'undefined'", {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<!-- Ordini disponibili --><div class="list media-list"><ul>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.data, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li><a href="/order-details/?order_id=';
              r += c(ctx_3.order_id, ctx_3);
              r += '" class="item-link item-content" style="background-color: ';
              r += c(ctx_3.bg_color, ctx_3);
              r += ';"><div class=item-media><img height=40px src=./static/images/cart-red.png></div><div class=item-inner><div class="item-title-row no-chevron"><div class=item-title><span style="font-weight: bold; color: #b11733;">Ristorante:</span> <b>';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.restaurant_name, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</b></div></div><div class="item-title-row no-chevron"><div class=item-title><span style="font-weight: bold; color: #b11733;">Ordine numero:</span> <b>#';
              r += c(ctx_3.order_number, ctx_3);
              r += '</b></div></div><div class=item-row><div class=item-title><b>Ordine per:</b> ';
              r += Template7Helpers.escape.call(ctx_3, ctx_3.name, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div><div class=item-title></div></div><div class=item-row><div class=item-title><b>Orario di ritiro:</b> ';
              r += Template7Helpers.js.call(ctx_3, "appUtilities.convertUTCToLocalDate(this.restaurant_time_limit).format('HH:mm')", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div><div class=item-title></div></div><div class=item-row><div class=item-title><b>Giorno di consegna:</b> ';
              r += Template7Helpers.js.call(ctx_3, "appUtilities.convertUTCToLocalDate(this.order_date).format('DD/MM/YYYY')", {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div><div class=item-title></div></div><div class=item-row><div class=item-title><b>Orario consegna:</b> ';
              r += Template7Helpers.js_if.call(ctx_3, "this.time_limit_status == 'pre_order'", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' ';
                  r += c(ctx_4.time_limit, ctx_4);
                  r += ' ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              r += Template7Helpers.js_if.call(ctx_3, "this.time_limit_status != 'pre_order'", {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' Il prima possibile ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div><div class=item-title></div></div></div></a></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },

  styleScoped: false
};

function load_available_orders(app) {
  var _request_params = {
    'session_token': appCore.session_token
  };
  app.request({
    url: api_url + 'driver_available_orders.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (availableOrdersData) {
      console.log("available orders data", availableOrdersData);
      let _page = $$('.page[data-name="available-orders"]')[0].f7Page;
      let _self = _page.$pageEl[0].f7Component;

      _self.$setState({
        data: availableOrdersData.data
      });
    },
    error: function () {
      app.dialog.alert(general_error_message, 'Attenzione', function () {}, "Errore");
    }
  });
}
    