import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';

// Import F7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';

// Import Cordova APIs
import cordovaApp from './cordova-app.js';

// Import Routes
import routes from './routes.js';

// Import Moment.js
import * as moment from 'moment';
import 'moment/locale/it';

// Import main app component
import App from '../app.f7.html';

import AppCore from './app-core.js';
import AppUtilities from './app-utilities.js';
import Arrive from './arrive-2.4.1.min.js';
import Geo from './geo.js';

window.app_type = 'driver';
window.app_version = '1.0.4.1';
window.debug_enabled = true;
window.device_type = '';

window.app_url = '';
window.push_state_root = '';

//window.api_url = 'https://api.jacknb.com/'; 
window.api_url = 'https://dev.api.jacknb.com/'; 
//window.api_url = 'http://localhost/api_jacknb/';

window.img_url = 'https://api.jacknb.com/';
//window.img_url = 'http://localhost/api_jacknb/';

window.general_error_message = "A causa della connessione a internet limitata le prestazioni dell'app potrebbero essere ridotte";

window.Arrive = Arrive;

console.log('App version: ' + app_version);

if(debug_enabled == false)
{
  var _console = {};

  window.console = _console;

  console.log = function(){};
  console.info = function(){};
  console.warn = function(){};
  console.error = function(){};
}

new AppCore(function()
{
});

window.appUtilities = new AppUtilities();
window.moment = moment;

window.ViewMain = null;
window.ViewLogin = null;
window.ViewAvailableOrders = null;
window.ViewAcceptedOrders = null;
window.ViewProfile = null;

window.f7app = new Framework7(
{
  root: '#app', // App root element
  component: App, // App main component
  id: 'com.jacknb.driver', // App bundle ID
  name: 'jacknb_driver', // App name
  theme: 'auto', // Automatic theme detection
  cache: false,
  cacheDuration: 0,
  pushState: true,

  // App routes
  routes: routes,

  // Register service worker
  serviceWorker: Framework7.device.cordova ? {} : 
  {
    path: '/service-worker.js',
  },
  // Input settings
  input: 
  {
    scrollIntoViewOnFocus: Framework7.device.cordova && !Framework7.device.electron,
    scrollIntoViewCentered: Framework7.device.cordova && !Framework7.device.electron,
  },
  // Cordova Statusbar settings
  statusbar: 
  {
    iosOverlaysWebView: true,
    androidOverlaysWebView: false,
  },
  on: 
  {
    init: function () 
    {
      var f7 = this;

      if (f7.device.cordova) 
      {
        // Init cordova APIs (see cordova-app.js)
        
        cordovaApp.init(f7);
      }

      ViewMain = f7app.views.create('.view-main',
      {
        url: 'index.html',
        pushState: true,
        pushStateRoot: document.location.pathname.split('index.html')[0],
        animate : false,
        on: 
        {
          init : function () 
          {
            var f7 = this;

            // Set push state root

            window.push_state_root = document.location.pathname.split('index.html')[0];

            console.log('Push state root: ' + window.push_state_root);

            // Set app url
            
            window.app_url = window.location.href;

            console.log('App url: ' + window.app_url);

            // Recupera i dati del dispositivo

            device_type = get_device_type();

            // Verifica se ci sono aggiornamenti disponibili

            get_current_app_version();

            // Prosegue col caricamento dell'applicazione

            on_app_load(f7);
          }
        }
      });
    },
    toolbarHide: function()
    {
    },
    toolbarShow: function()
    {
    }
  },
  methods: 
  {
    onBackKeyDown: function() 
    {
      var leftp = app.panel.left && app.panel.left.opened;
      var rightp = app.panel.right && app.panel.right.opened;

      if (leftp || rightp) 
      {
          f7app.panel.close();

          return false;
      }
      else if ($$('.modal-in').length > 0)
      {
          f7app.dialog.close();
          f7app.popup.close();

          return false;
      } 
      else if (f7app.views.main.router.url == '/home/') 
      {
        navigator.app.exitApp();
      }
      else 
      {
        let _current_page_name = ViewHome.router.currentPageEl.getAttribute('data-name');

        console.log('current page name' + _current_page_name);

        if(_current_page_name == "cart-item-details")
        {
          return;
        }

        ViewMain.router.back();
      }
    }
  }
});

// --- General APP functions ---

function on_app_load(f7)
{  
  let _user_logged = appUtilities.user_is_logged();

  if(_user_logged == false)
  {
    return;
  }

  appCore.session_token = appUtilities.getParam('session-token');

  // Verifica se il token di sessione è valido

  var _request_params = 
  {
    'token' : appCore.session_token
  };

  f7app.request(
  {
    url: api_url + 'verify_token.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (data) 
    {
      console.log("verify token", data);

      let _success = parseFloat(data.success);

      if(_success == false)
      {
        // Effettua il logout

        log_out();
      }
      else
      {
        // === Token valido ===

        send_app_and_device_data();

        // Avvia il controllo periodico degli ordini disponibili

        setInterval(function()
        { 
          periodic_available_orders_check();
        },
        10000);
      }
    },
    error: function () 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
    }
  });
}

// --- General DOM ---

$$(document).on('DOMNodeInserted', '.pac-container', function() 
{
  $$('.pac-item, .pac-item span').addClass('no-fastclick');
});

// --- General Pages ---

$$(document).on('page:beforein', function (e)
{
  //f7app.preloader.hide();
});

$$(document).on('page:afterin', function (e)
{
  //f7app.preloader.hide();
});

// --- Login, logout, signup, confirm account

$$(document).on('click', '#login', function (e) 
{
  e.preventDefault();

  log_in();
});

$$(document).on('click', '#signup-save', function (e) 
{
  e.preventDefault();

  register();
});

$$(document).on('click', '#confirm-profile', function (e) 
{
  e.preventDefault();

  confirm_profile();
});

$$(document).on('click', '#logout', function (e) 
{
  log_out();
});

$$(document).on('click', '#request-reset-password', function (e) 
{
  let _email = $$('#signin-email').val().trim();

  if(_email == "")
  {
    f7app.dialog.alert("Inserire l'indirizzo email!");

    return;
  }

  let _email_isValid = appUtilities.validateEmail(_email);

  if(_email_isValid == false)
  {
    f7app.dialog.alert("Inserire un indirizzo email valido!", "Attenzione!");

    return;
  }

  let _request_params = 
  {
    'email' : _email
  };

  f7app.request(
  {
    url: api_url + 'request_reset_password.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (data) 
    {
      console.log('reset password', data);

      if(data.success == true)
      {
        f7app.dialog.alert("Richiesta inviata con successo! A breve riceverai un'email per completare la reimpostazione della password.");
      }
      else
      {
        f7app.dialog.alert("Impossibile richiedere la reimpostazione della password! E' stato inserito un indirizzo email valido?");
      }
    },
    error: function () 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
    }
  });
});

$$(document).on('input', '.period-filter', function (e) 
{
	e.preventDefault();

	let _from = (e.target.id == 'from-period') ? e.target.value : ViewProfile.$el.find('#from-period').val();
	let _to = (e.target.id == 'to-period') ? e.target.value : ViewProfile.$el.find('#to-period').val();

  load_period_totals(_from, _to);
});

function log_in()
{
  let _email = $$('#signin-email').val().trim();
  let _password = $$('#signin-password').val();
  //let _recaptcha_response = $$('[name ="g-recaptcha-response"]').val().trim();

  if(_email == "")
  {
    f7app.dialog.alert("Inserire l'indirizzo email!");

    return;
  }

  let _email_isValid = appUtilities.validateEmail(_email);

  if(_email_isValid == false)
  {
    f7app.dialog.alert("Inserire un indirizzo email valido!", "Attenzione!");

    return;
  }

  if(_password == "")
  {
    f7app.dialog.alert("Inserire la password!");

    return;
  }

  /*
  if(_recaptcha_response == "")
  {
    f7app.dialog.alert("Confermare di non essere un robot! :D");

    return;
  }
  */

  let _request_params = 
  {
    'email' : _email,
    'password' : _password,
    'user_type' : 'Driver',
    //'recaptcha_response' : _recaptcha_response
  };

  f7app.request(
  {
    url: api_url + 'login.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (data) 
    {
      console.log("login", data);

      let _success = parseFloat(data.success);

      if(_success == 1)
      {
        let _sessionToken = data.data.session_token;

        appUtilities.setParam('session-token', _sessionToken);

        appCore.session_token = _sessionToken;

        window.location.href = app_url;
      }
      else
      {
        if(data.data !== null)
        {
          let _status = parseInt(data.data.status);

          if(_status == 0)
          {
            ViewMain.router.navigate('/confirm-profile/');
          }
        }
        else
        {
          let _message = data.msg;

          f7app.dialog.alert(_message);
        }
      }
    },
    error: function () 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
    }
  });
}

function confirm_profile()
{
  let _otp = $$('#confirm-account-otp').val();

  if(_otp.length != 6)
  {
    f7app.dialog.alert("Codice temporaneo inserito non valido!");

    return;
  }

  let _request_params = 
  {
    'otp' : _otp,
  };

  f7app.request(
  {
    url: api_url + 'confirm_account.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (data) 
    {
      console.log("confirm account", data);

      let _success = parseFloat(data.success);

      console.log(_success);

      if(_success == 1)
      {
        let _sessionToken = data.data.session_token;

        appUtilities.setParam('session-token', _sessionToken);

        appCore.session_token = _sessionToken;

        window.location.href = app_url;
      }
      else
      {
        f7app.dialog.alert("Impossibile completare la creazione del profilo: " + data.msg);
      }
    },
    error: function () 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
    }
  });
}

function register()
{
  let _accept_terms_checked = ($$('#accept-terms:checked').length > 0);
  let _email = $$('#signup-email').val().trim();
  let _password = $$('#signup-password').val().trim();
  let _name = $$('#signup-name').val().trim();
  let _surname = $$('#signup-surname').val().trim();
  let _phone = $$('#signup-phone').val().trim();
  let _user_type = "Driver";
  let _device_type = "Web";
  let _firebase_token = "";
  let _latitude = 0;
  let _longitude = 0;

  if(_email == "")
  {
    f7app.dialog.alert("Inserire un indirizzo email!", "Attenzione!");

    return;
  }

  let _email_isValid = appUtilities.validateEmail(_email);

  if(_email_isValid == false)
  {
    f7app.dialog.alert("Inserire un indirizzo email valido!", "Attenzione!");

    return;
  }

  if(_password == "")
  {
    f7app.dialog.alert("Inserire la password!", "Attenzione!");

    return;
  }

  if(_name == "")
  {
    f7app.dialog.alert("Inserire il nome!", "Attenzione!");

    return;
  }

  if(_surname == "")
  {
    f7app.dialog.alert("Inserire il cognome!", "Attenzione!");

    return;
  }

  if(_phone == "")
  {
    f7app.dialog.alert("Inserire il numero di telefono!", "Attenzione!");

    return;
  }

  if(_phone.length < 9)
  {
    f7app.dialog.alert("Inserire un numero di telefono valido!", "Attenzione!");

    return;
  }

  if(_accept_terms_checked == false)
  {
    f7app.dialog.alert("Accettare i termini e le condizioni prima di proseguire!", "Attenzione!");

    return;
  }

  let _request_params = 
  {
    'email' : _email,
    'password' : _password,
    'user_type' : _user_type,
    'device_type' : _device_type,
    'firebasetoken' : _firebase_token,
    'latitude' : _latitude,
    'longitude' : _longitude,
    'name' : _name,
    'surname' : _surname,
    'phone_number' : _phone
  };

  f7app.request(
  {
    url: api_url + 'register.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (data) 
    {
      console.log(data);

      let _success = parseFloat(data.success);

      if(_success == 1)
      {
        ViewMain.router.navigate('/confirm-profile/');
      }
      else
      {
        f7app.dialog.alert(data.msg);
      }
    },
    error: function () 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
    }
  });
}

function log_out()
{
  appUtilities.removeParam('session-token');

  window.location.href = app_url;
}

// NB: load_period_totals viene richiamata dalla pagina "profile"
// per questo motivo è inserita all'interno dell'oggetto window

window.load_period_totals = function(from, to)
{
  // Carica i totali degli ordini

  let _periodFrom = from;
  let _periodTo = to;

  let _default_period_from = moment().clone().startOf('month').format('YYYY-MM-DD');
  let _default_period_to = moment().clone().endOf('month').format('YYYY-MM-DD');

  _periodFrom = (_periodFrom == "") ? _default_period_from : _periodFrom;
  _periodTo = (_periodTo == "") ? _default_period_to : _periodTo;

  let _request_params = 
  {
    'session_token' : appCore.session_token,
    'period_from' : _periodFrom,
    'period_to' : _periodTo
  };

  f7app.request(
  {
    url: api_url + 'driver_period_orders.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (periodOrdersData) 
    {
      console.log('period orders data', periodOrdersData);
      
      let _page = $$('.page[data-name="profile"]')[0].f7Page; 
      let _self = _page.$pageEl[0].f7Component; 
      
      _self.$setState(
      { 
        periodOrdersData: periodOrdersData.data,
        period_from: _periodFrom,
        period_to: _periodTo
      });
    },
    error: function () 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
    }
  });
}

// --- Orders ---

$$(document).on('click', '#accept', function (e) 
{
  $$(this).css('display', 'none');

  let _order_id = $$(this).data('order-id');

  set_order_status(_order_id, 1, function()
  {
    ViewAvailableOrders.router.navigate("/available-orders/", 
    {
      ignoreCache: true,
      force: true
    });

    ViewAvailableOrders.router.refreshPage();

    f7app.tab.show('#view-accepted-orders', '#tab-accepted-orders', true);
  });
});

$$(document).on('click', '#reject', function (e) 
{
  $$(this).css('display', 'none');

  let _order_id = $$(this).data('order-id');

  set_order_status(_order_id, 0, function()
  {
    ViewAcceptedOrders.router.navigate("/accepted-orders/", 
    {
      ignoreCache: true,
      force: true
    });

    ViewAcceptedOrders.router.refreshPage();
    
    f7app.tab.show('#view-available-orders', '#tab-available-orders', true);
  });
});

$$(document).on('click', '#pickup', function (e) 
{
  $$(this).css('display', 'none');

  let _order_id = $$(this).data('order-id');

  set_order_status(_order_id, 2, function()
  {
    ViewAcceptedOrders.router.refreshPage();
  });
});

$$(document).on('click', '#delivered', function (e) 
{
  if(confirm("Confermare la consegna dell'ordine?") == false)
  {
    return;
  }

  let _order_id = $$(this).data('order-id');

  $$(this).css('display', 'none');

  set_order_status(_order_id, 4, function()
  {
    ViewAcceptedOrders.router.navigate("/accepted-orders/", 
    {
      ignoreCache: true,
      force: true
    });

    ViewAvailableOrders.router.refreshPage();

    f7app.tab.show('#view-available-orders', '#tab-available-orders', true);
  });
});

function set_order_status(order_id, status, callback)
{
  let _request_params = 
	{
    'status' : status,
    'order_id': order_id,
    'session_token' : appCore.session_token
	};

	f7app.request(
	{
		url: api_url + 'driver_set_order_status.php',
		dataType: 'json',
		data: _request_params,
		method: 'POST',
		cache: false,
		crossDomain: true,
		success: function (data) 
		{
			console.log('set order status: ', data);

			let _success = parseFloat(data.success);

			if(_success == 1)
			{
        if(typeof callback !== "undefined")
        {
          callback();
        }
			}
			else
			{
				f7app.dialog.alert(data.msg);
			}
		},
		error: function (error) 
		{
			f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
		}
	});
}

function periodic_available_orders_check()
{
  // Controllo periodico ordini in attesa

  let _user_logged = appUtilities.user_is_logged();

  if(_user_logged == false)
  {
    return;
  }

  var _request_params = 
  {
    'session_token' : appCore.session_token
  };

  f7app.request(
  {
    url: api_url + 'driver_available_orders.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (availableOrdersData) 
    {
      console.log("available orders data", availableOrdersData);

      let _page = $$('.page[data-name="available-orders"]')[0].f7Page; 
      let _self = _page.$pageEl[0].f7Component; 
      
      _self.$setState(
      { 
        data: availableOrdersData.data
      });
    },
    error: function () 
    {
      _app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
    }
  });
}

// --- Profile ---

$$(document).on('click', '#update-profile', function (e) 
{
  let _name = $$('#profile-name').val().trim();
  let _surname = $$('#profile-surname').val().trim();
  let _phoneNumber = $$('#profile-phone-number').val().trim();

  if(_name == "")
  {
    f7app.dialog.alert("Inserire un nome valido!", "Attenzione!");

    return;
  }

  if(_surname == "")
  {
    f7app.dialog.alert("Inserire un cognome valido!", "Attenzione!");

    return;
  }

  if(_phoneNumber == "")
  {
    f7app.dialog.alert("Inserire un numero di telefono valido!", "Attenzione!");

    return;
  }

  if(_phoneNumber.length < 9)
  {
    f7app.dialog.alert("Inserire un numero di telefono valido!", "Attenzione!");

    return;
  }

  let _request_params = 
  {
      'name' : _name,
      'surname' : _surname,
      'phone_number' : _phoneNumber,
      'session_token' : appCore.session_token
  };

  f7app.request(
  {
    url: api_url + 'edit_driver_profile.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (data) 
    {
      console.log(data);

      let _success = parseFloat(data.success);
      let _msg = data.msg;

      f7app.dialog.alert(_msg);
    },
    error: function () 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){});
    }
  });
});

// --- Device and App data ---

function get_current_app_version()
{
  var _request_params = 
  {
    'app_type' : app_type
  };

  f7app.request(
  {
    url: api_url + 'current_app_version.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (currentAppVersionData) 
    {
      let _success = parseFloat(currentAppVersionData.success);

      console.log("current app version data", currentAppVersionData);

      if(_success == 1)
      {
        let _current_app_version = currentAppVersionData.data.app_version;
        let _last_update_version = appUtilities.getParam('last-update-version');

        if(_last_update_version == _current_app_version)
        {
            // E' già stata gestita questa versione

            return true;
        }

        if(_current_app_version != app_version)
        {
          // Salva in locale che si sta gestendo questa nuova versione

          appUtilities.setParam('last-update-version', _current_app_version);

          // Update app

          if
          (
            Framework7.device.android == true
            || Framework7.device.cordova == true
            || Framework7.device.ios == true
            || Framework7.device.ipad == true
            || Framework7.device.iphone == true
            || Framework7.device.ipod == true
            || Framework7.device.macos == true
            || Framework7.device.phonegap == true
            || Framework7.device.webView == true
            || Framework7.device.webview == true
          )
          {
            // App nativa

            /*
            alert(`
              È presente una versione piu recente dell'applicazione.
              Ti consigliamo di effettuare l'aggiornamento in quanto alcune funzionalità della versione corrente potrebbero non funzionare, Grazie.
              `, "Aggiornamento");
            */
          }
          else
          {
            // App web

            alert(`
              E' presente un nuovo aggiornamento dell'applicazione. 
              Chiudendo questo messaggio verrà installato il nuovo aggiornamento e riavviata l'applicazione.
              `, "Aggiornamento");
        
            if ('serviceWorker' in navigator) 
            {
              // Cancella la cache dell'applicazione
  
              caches.keys().then(function(cacheNames) 
              {
                cacheNames.forEach(function(cacheName) 
                {
                  caches.delete(cacheName);
                });
              });
  
              // Ricarica la pagina
  
              window.location.href = app_url;
            }
            else
            {
              // Impossibile cancellare la cache
  
              alert("Si è verificato un errore durante l'aggiornamento: si prega di contattare il supporto tecnico.");
            }
          }
        }
      }
    },
    error: function () 
    {
      f7app.dialog.alert(general_error_message, 'Attenzione', function(){}, "Errore");
    }
  });
}

function get_device_type()
{
  let _ret = '';

  if(Framework7.device.android == true)
  {
    _ret = 'android';
  }
  else if(Framework7.device.androidChrome == true)
  {
    _ret = 'android-chrome';
  }
  else if(Framework7.device.cordova == true)
  {
    _ret = 'cordova';
  }
  else if(Framework7.device.desktop == true)
  {
    _ret = 'desktop';
  }
  else if(Framework7.device.edge == true)
  {
    _ret = 'edge';
  }
  else if(Framework7.device.electron == true)
  {
    _ret = 'electron';
  }
  else if(Framework7.device.firefox == true)
  {
    _ret = 'firefox';
  }
  else if(Framework7.device.ie == true)
  {
    _ret = 'ie';
  }
  else if(Framework7.device.ios == true)
  {
    _ret = 'ios';
  }
  else if(Framework7.device.ipad == true)
  {
    _ret = 'ipad';
  }
  else if(Framework7.device.iphone == true)
  {
    _ret = 'iphone';
  }
  else if(Framework7.device.ipod == true)
  {
    _ret = 'ipod';
  }
  else if(Framework7.device.macos == true)
  {
    _ret = 'macos';
  }
  else if(Framework7.device.nwjs == true)
  {
    _ret = 'nwjs';
  }
  else if(Framework7.device.phonegap == true)
  {
    _ret = 'phonegap';
  }
  else if(Framework7.device.webView == true || Framework7.device.webView == true)
  {
    _ret = 'webview';
  }
  else if(Framework7.device.windows == true)
  {
    _ret = 'windows';
  }

  return _ret;
}

function send_app_and_device_data()
{
  var _request_params = 
  {
    'session_token' : appCore.session_token,
    'app_version' : app_version,
    'os_version' : Framework7.device.os,
    'device_type' : device_type
  };

  f7app.request(
  {
    url: api_url + 'device_app_data.php',
    dataType: 'json',
    data: _request_params,
    method: 'POST',
    cache: false,
    crossDomain: true,
    success: function (data) 
    {
    },
    error: function () 
    {
    }
  });
}